body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
}

/* .makeStyles-paper-11, */
/* .makeStyles-paper-11 h2, */
/* .MuiTypography-body1,
.MuiPaper-root, */
h2 {
  color: #f9d749 !important;
}

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  color: #f9d749;
}

.MuiToolbar-regular {
  color: #e0e3bd;
}

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover,
.MuiListItem-button:hover,
.MuiButtonBase-root:hover {
  color: #fff !important;
}

code {
  font-family: 'Rubik', monospace;
}

.wallet-button {
  color: white;
  font-family: 'Rubik', sans-serif !important;
  font-size: 1.2rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
.wallet-button:hover {
  color: #f9d749 !important;
}

.navLink {
  color: white !important;
}

.navLink:hover {
  color: #f9d749 !important;
}

.shinyButton {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  color: rgb(170, 73, 249) !important;
  box-shadow: 0px 0px 5px 0px rgb(170, 73, 249) !important;
}

.shinyButtonGreen {
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  color: #00ff00 !important;
  box-shadow: 0px 0px 5px 0px rgb(0, 255, 0);
}

.shinyButtonDisabled {
  border-radius: 15px;

  color: grey !important;
  box-shadow: 0px 0px 5px 0px grey;
}

.shinyButton:hover {
  background-color: rgb(170, 73, 249) !important;
  color: #171923 !important;
}

.shinyButtonGreen:hover {
  background-color: #00ff00 !important;
  color: #171923 !important;
}

.shinyButtonSecondary {
  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 15px;
  font-size: 1rem !important;
  padding-left: 5px;
  background-color: rgb(193, 73, 249) !important;
  color: #171923 !important;
  /* box-shadow: 0px 0px 5px 0px rgba(251,221,76,1); */
}

.account {
  display: flex;
  align-items: center;
}

.account > img,
.account > div {
  margin-right: 8px;
}

.shinyButtonDisabledSecondary {
  border-radius: 15px;

  color: grey !important;
  box-shadow: 0px 0px 5px 0px grey;
}

.shinyButtonSecondary:hover {
  background-color: rgb(234, 73, 249) !important;
  color: #171923 !important;
  box-shadow: 0px 0px 5px 0px rgb(234, 73, 249) !important;
}

.keen {
  background-image: url('./assets/img/keen1.png');
}

.iskeen {
  background-image: url('./assets/img/keen2.png');
}

.avax {
  background-image: url('./assets/img/keen3.png');
}
